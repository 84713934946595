import React, { Component } from "react";
import Index1 from "./pages/Index1/Index1";
// import "./CustomScrollbar.scss";
// import routes from "./routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";

// Import Scss
import "./theme.scss";

//Import Icon Css
import "./assets/css/materialdesignicons.min.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {/* {routes.map((route, idx) => ( */}
            <Route path={"/"} component={Index1} />
            {/* ))} */}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
